<template>
  <div class="w-100 p-l-30 p-r-30">
    <div v-if="!addNewReminderScreen">
      <div class="row d-flex justify-content-between m-r-0">
        <div class="col select-tournament">
          <select v-model="tournamentId"
                  class="form-control color-33 select-style select-tournament form-select"
                  @change="getDataTournament">
            <option
                v-for="(tournament, tournamentId) in tournamentList"
                :key="'tournamentId'+ tournamentId"
                :value="tournament.tournamentId"
            >
              {{ tournament.tournamentName }}
            </option>
          </select>
          <div v-show="emptyTournament" class="form-error">表示期間内に大会はありません</div>
        </div>
        <div class="float-right width-150 scan-btn">
          <button class="btn btn-primary height-48 width-144 tournament-search-button" v-bind:disabled="tournamentId === 0"
                  @click="scanResultCTA">
            入場管理
          </button>
        </div>
      </div>
      <div class="row m-t-25 m-b-20">
        <div class="col-2">
          <button class="btn custom-button fw-bold" v-bind:disabled="tournamentId === 0" @click="addNewReminder"><i
              class="fas fa-plus float-start mt-1"></i>新規登録
          </button>
        </div>
      </div>
      <div class="datatable">
        <Datatable
            :columns="columns"
            :getData="getData"
            :loading="loading"
            :response="response"
            :sortable="true"
            @cta="cta">
        </Datatable>
      </div>
    </div>
  </div>
</template>

<script>
import {datatable} from "@/constants";
import Datatable from "../components/datatable/Datatable";
import {mapGetters} from "vuex";
import Common from "../assets/jsadminclient/common";
import Helper from "../assets/jsadminclient/helper";

export default {
  name: "Reminders",
  components: {Datatable},
  data() {
    return {
      response: {
        sort: 'time',
        order: 'asc',
        current_page: 1,
        per_page: datatable.default.perPage
      },
      tournamentId: 0,
      tournamentList: {},
      tournamentName: '',
      reminder: {},
      addNewReminderScreen: false,
      loading: true,
      emptyTournament: false,
      columns: datatable.columns.reminders,
      errors: []
    }
  },
  computed: {
    ...mapGetters(["config"]),
    // years() {
    //     const year = new Date().getFullYear();
    //     return Array.from(
    //         {length: year - 1919},
    //         (value, index) => 1919 + (index + 1)
    //     )
    // },
  },
  mounted() {
    this.restoreSearchQuery()
    this.initialPageLoadHandler();
    this.loadTournamentList()
  },
  methods: {
    onKeyDown(event) {
      if (event.keyCode !== 13) {
        return
      }
      this.search()
    },
    initialPageLoadHandler() {
      if (this.defaultResponse) {
        this.response = this.defaultResponse
      }

      Object.assign(this.columns[2], {
        format: (sendTargetId) => {
          if (sendTargetId === 1)
            return '関係者全員'
        },
      })
      Object.assign(this.columns[5], {
        format: (details) => {
          return Helper.showCTA()
        },
      })
      // this.getRoles()
    },
    addNewReminder() {
      this.$router.push({
        name: 'ReminderRegistration',
        params: {
          tournamentId: Number(this.tournamentId),
        },
        query: this.createSearchQuery()
      })
    },
    async getData() {
      try {
        if (this.emptyTournament) {
          this.loading = false
          this.response.data = [];
          return
        }

        this.loading = true;
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        var result = await api.tournamentsTournamentIdRemindersGetAsync(
            this.tournamentId,
            this.response.current_page,
            this.response.per_page,
            this.response.sort,
            this.response.order
        )

        if (result) {
          this.loading = false
          // this.reloadKey += 1
          this.response = Helper.formatResponse(result)
          // map user data
          this.response.data = result.items.map((item) => {
            return {
              reminderId: item.reminderId,
              reminderTitle: item.reminderTitle,
              time: this.hourMinuteFormatter(item.hour, item.minute),
              subject: item.subject,
              body: item.body,
              sendTargetId: item.sendTargetId
            }
          })
          Common.updateCurrentPageQuery(this, this.createSearchQuery())
        }
      } catch (e) {
        this.response.data = [];
        this.loading = false;
        this.errors = Common.getErrorList(e.response, this.errors)

        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    hourMinuteFormatter(hour, minute) {
      return moment(hour + ':' + minute + ':00', 'HHmmss').format('HH:mm')
      // let time = ''
      // time += hour < 10 ? '0'+hour.toString() : time += hour.toString()
      // time += minute < 10 ? ':0'+minute.toString() : time += ':'+minute.toString()
      // return time
    },
    async getDataTournament() {
      Common.saveSelectTournamentIdCommon(this.tournamentId)
      await this.getData()
    },
    async loadTournamentList() {
      try {
        this.loading = true
        const start = Common.getHomeStartDate()
        const end = Common.getHomeEndDate()
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token))
        var result = await api.tournamentsListGetAsync(start, end)
        if (result.items) {
          this.loading = false
          this.tournamentList = result.items
          const savedId = this.tournamentId > 0 ? this.tournamentId : Common.getSelectTournamentIdCommon()
          if (savedId !== 0 && this.tournamentList.find((f) => f.tournamentId === savedId)) {
            this.tournamentId = savedId
          } else {
            if (this.tournamentList.length > 0) {
              this.tournamentId = Number(this.tournamentList[0].tournamentId)
            } else {
              this.emptyTournament = true
            }
          }

          this.getData()
        }
      } catch (e) {
        this.loading = false
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    scanResultCTA() {
      this.$router.push({name: 'Scan', params: {tournamentId: this.tournamentId}})
    },
    cta(key, row) {
      const reminderId = row[key];
      this.$router.push({
        name: 'ReminderDetail',
        params: {
          tournamentId: Number(this.tournamentId),
          reminderId: reminderId
        },
        query: this.createSearchQuery()
      })
    },
    createSearchQuery(orgQuery = null) {
      return Common.appendSearchQuery(
          orgQuery,
          {
            sort: this.response.sort,
            order: this.response.order,
            currentPage: this.response.current_page,
            perPage: this.response.per_page
          })
    },
    restoreSearchQuery() {
      Common.restoreQuery(this.response, 'sort', this.$route.query.sort)
      Common.restoreQuery(this.response, 'order', this.$route.query.order)
      Common.restoreQuery(this.response, 'per_page', this.$route.query.perPage)
      Common.restoreQuery(this.response, 'current_page', this.$route.query.currentPage)
    },
  }
}
</script>

<style lang="scss" scoped>
.reminders table tr {
  th, td {
    &:nth-child(1) {
      width: 200px;
    }

    &:nth-child(2) {
      width: 100px;
    }

    &:nth-child(3) {
      width: 130px;
    }

    @media only screen and (max-width: 1000px) {
      padding: 10px;
      &:nth-child(2), &:nth-child(3) {
        width: 100px;
      }
    }
    @media only screen and (max-width: 900px) {
      padding: 8px;
      &:nth-child(2), &:nth-child(3) {
        width: 80px;
      }
    }
    @media only screen and (max-width: 767px) {
      padding: 6px;
      &:nth-child(2), &:nth-child(3) {
        width: 70px;
      }
    }
  }
}

.custom-button {
  background: #C95D6C;
  color: #FFFFFF;
  border-radius: 4px;
  width: 160px;
  height: 32px;
  line-height: 14px;
}


.select-tournament.form-control {
  background: #f2ecf0;
  color: #888888;
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 13px 13px;
}

.tournament-search-button {
  height: 48px;
  background: #C95D6C;
  border-radius: 4px;
  color: #FFFFFF;
  width: 100%;
}

a {
  color: #333333;
}

.tournamentId_box {
  width: calc(100% - 160px);
}

.dob-section {
  .parent-block {
    .child-block {
      position: absolute;
      top: 0px;
      background: #F8F8F8;
      justify-content: center;
      align-items: center;
      display: flex;
      border: 1px solid #F0E7ED;
      border-radius: 0px 4px 4px 0px;
      height: 100%;
      max-height: 48px;
      width: 2.25rem;
      z-index: 9999;
      right: 0;
      color: #888888;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }

  .form-control {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
}

.fas {
  &.fa-plus {
    margin-top: 0px !important;
  }
}

.custom-select-year {
  width: 28%;
  margin-right: 23px;

  &:first-child {
    width: 40%;
  }

  &:last-child {
    width: 28%;
    margin-right: 0px !important;
  }
}

</style>
